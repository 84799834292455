import './App.css';
import GameRoot from './GameRoot';

function App() {
  return (
    <GameRoot />
  );
}

export default App;
