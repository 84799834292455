/* eslint-disable default-case */
/* eslint-disable array-callback-return */
/* eslint-disable no-extra-bind */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-unreachable */
/* eslint-disable new-parens */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-extend-native */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as pot1 from "./svg-img/pot1.svg";
import * as starsFGame from "./svg-img/stars fgame.svg";
import * as floorBG from "./svg-img/floorbgtv.svg";
import * as pot1Copy from "./svg-img/pot1 - Copy.svg";
import * as piZZZa from "./png-img/PIZZZZZA.png";
import * as dJBoard from "./png-img/dj-board-hiq.jpg";
import * as speeKA from "./png-img/speeka.jpg";
import * as gunShot from "./sounds/fv4.wav.mp3";
import * as pickUp from "./sounds/pickup.mp3";
import * as breakPot from "./sounds/break.mp3";
import * as stepNow from "./sounds/step.mp3";
import * as trapNorm from "./sounds/trap without clip.wav.mp3";
import * as trapDist from "./sounds/trap with distortion.mp3";
import * as rockNorm from "./sounds/rock is norm.mp3";
import * as rockDist from "./sounds/rock is dist.mp3";
import React, { useEffect, useState } from 'react';
document.addEventListener("wheel", function (e) {
    if (e.ctrlKey) {
        e.preventDefault();
    }
}, { passive: false });
document.addEventListener("keydown", function (e) {
    let cd = e.code;
    let kc = e.key;
    if (e.ctrlKey && (cd === "Equal" || cd === "Minus" || kc === "-" || kc === "=")) {
        e.preventDefault();
    }
}, { passive: false });
function FloorFrame() {
    const s10a = {
        background: `#eee url(${floorBG.default})`,
        display: "inline-block",
        width: 1000,
        height: 1000,
        position: "absolute",
        ...Math.w2s(-500, -500).acj().css()
    };
    return <div style={s10a}></div>;
}
const rootStyleVars = document.querySelector(":root").style;
function setBgColor(nc) {
    rootStyleVars.setProperty("--FLOORCOLOR", nc);
}
function setLightLocation(x, y) {
    x = floatPx(x);
    y = floatPx(y);
    lightLocation = [parseFloat(x), parseFloat(y)];
    rootStyleVars.setProperty("--LIGHTX", x);
    rootStyleVars.setProperty("--LIGHTY", y);
}
function floatPx(str) {
    return parseFloat(str) + "px";
}
setBgColor("#0060a5");
Object.prototype.sca = function () {
    return [this.x, this.y];
}
Object.prototype.ctca = function () {
    return [this.left + "px", this.top + "px"];
}
Object.prototype.css = function () {
    //let b2a = 1;// !mainGame.isPaused;
    return {
        top: this.y + Math.rmid() * mainGame.posNoise,// * b2a,
        left: this.x + Math.rmid() * mainGame.posNoise //* b2a
    };
}
Array.prototype.acj = function () {
    return {
        x: this[0],
        y: this[1]
    };
}
Array.prototype.rand = function () {
    return this[Math.floor(Math.random() * this.length)];
}
Array.prototype.sum = function () {
    let r = 0;
    for (let i = 0; i < this.length; i++) {
        r += this[i];
    }
    return r;
}
Math.d2l = function (x1 = 0, y1 = 0, x2 = 0, y2 = 0, x3 = 0, y3 = 0) {
    if (x1 === x2) return [x1, y3];
    let m1 = (y2 - y1) / (x2 - x1);
    let b1 = y1 - m1 * x1;
    let m2 = -1 / m1;
    let b2 = y3 - m2 * x3;
    let x4 = (b2 - b1) / (m1 - m2);
    let y4 = m2 * x4 + b2;
    return [x4, y4];
}
Math.s2w = function (x, y) {
    return [x + mainGame.player.x - window.innerWidth / 2, y + mainGame.player.y - window.innerHeight / 2];
}
Math.w2s = function (x, y, r = 0) {
    return [x - mainGame.player.x + window.innerWidth / 2 - r, y - mainGame.player.y + window.innerHeight / 2 - r];
}
Math.ln_int = function(x1, y1, x2, y2, x3, y3, x4, y4, dd) {
    let ua, ub, denom = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);
    if (denom === 0) {
        return {};
    }
    ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denom;
    ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denom;
    let obj = {
        x: x1 + ua * (x2 - x1),
        y: y1 + ua * (y2 - y1),
        seg1: ua >= 0 && ua <= 1,
        seg2: ub >= 0 && ub <= 1
    };
    obj.dist = obj.seg1 && obj.seg2 ? Math.hypot(obj.x - x1, obj.y - y1) : dd;
    return obj;
}
Math.rbtw = function (n, a) {
    return this.random() * (a - n) + n;
}
Math.aav = function (a, ...b) {
    return a.map(function (t, i) {
        return (t + b.map(y => y[i]).sum()) / (b.length + 1);
    });
}
Math.clamp = function (a, n, x) {
    return this.min(this.max(a, n), x);
}
Math.rmid = function () {
    return this.random() - .5;
}
Math.avg = function (...nums) {
    return nums.length ? nums.sum() / nums.length : 0;
}
Math.mkn = function (x) {
    if (Number.isNaN(Number(x))) return 0;
    return x;
}
Math.chance = function (p) {
    return this.random() < p;
}
Math.rdv = function (a, b = 100) {
    return this.round(a / b);
}
let aSounds = [];
function playSound(src, vol = 1) {
    let ad = document.createElement("audio");
    ad.src = src;
    ad.volume = vol;
    document.body.appendChild(ad);
    ad.onended = function () {
        this.remove();
    };
    ad.play();
    setTimeout(function () {
        ad.remove();
    }, 1000);
}
function SoundPlayer({ obj, loop, id }) {
    const volume = obj.vol;
    const onLoad = function (ev) {
        ev.target.volume = Math.clamp(volume, 0, 1);
    };
    if (id) return <audio src={obj.src} loop={loop} onCanPlay={onLoad} autoPlay={true} id={id}></audio>;
    return <audio loop={loop} onCanPlay={onLoad} autoPlay={true} id={id ? id : obj.id.toString().split(".")[1]}><source src={obj.src} /></audio>;
}
SoundPlayer.defaultProps = {
    volume: 1
};
function AllSounds() {
    const ost = mainGame.sound;
    return <>
        {aSounds.map(function (sound, k1) {
            return <SoundPlayer obj={sound} key={k1} />;
        })}
        <SoundPlayer obj={ost[0]} loop={true} id="OSTNorm" />
        <SoundPlayer obj={ost[1]} loop={true} id="OSTDist" />
    </>;
}
const _ms = {
    x: 0,
    y: 0
};
class World {
    constructor(c = "#0060a5") {
        let b = [...arguments];
        b.shift();
        b.push(new Block(-1650, -1650, 3300, 150), new Block(-1650, 1500, 3300, 150), new Block(-1650, -1650, 150, 3300), new Block(1500, -1650, 150, 3300), new Block(-575, -575, 1150, 150), new Block(-575, -575, 150, 1150), new Block(425, -575, 150, 1150), new Block(-575, 425, 400, 150), new Block(175, 425, 400, 150));
        this.b = b;
        this.c = c;
        this.entities = [];
        this._exec = () => null;
    }

    execute() {
        this.entities = [];
        gameUtil.addPot(-200, 750, this);
        gameUtil.addPot(200, 750, this);
        gameUtil.addPot(-400, 750, this);
        gameUtil.addPot(400, 750, this);
        gameUtil.addPot(-600, 750, this);
        gameUtil.addPot(600, 750, this);
        gameUtil.addPot(-200, -750, this);
        gameUtil.addPot(200, -750, this);
        gameUtil.addPot(-400, -750, this);
        gameUtil.addPot(400, -750, this);
        gameUtil.addPot(-600, -750, this);
        gameUtil.addPot(600, -750, this);
        gameUtil.addPot(0, -750, this);
        gameUtil.addPot(-750, -200, this);
        gameUtil.addPot(-750, 200, this);
        gameUtil.addPot(-750, -400, this);
        gameUtil.addPot(-750, 400, this);
        gameUtil.addPot(-750, -600, this);
        gameUtil.addPot(-750, 600, this);
        gameUtil.addPot(-750, 0, this);
        gameUtil.addPot(750, -200, this);
        gameUtil.addPot(750, 200, this);
        gameUtil.addPot(750, -400, this);
        gameUtil.addPot(750, 400, this);
        gameUtil.addPot(750, -600, this);
        gameUtil.addPot(750, 600, this);
        gameUtil.addPot(750, 0, this);
        this._exec();
    }
}
class Health {
    constructor(max = 100, color = "#0d4", o) {
        this._max = max;
        this._h = max;
        this.lD = 0;
        this.lH = 0;
        this.color = color;
        this.inv = false;
        this.owner = o;
    }

    get percent() {
        return this.health / this.max;
    }

    get max() {
        return this._max;
    }

    set max(m) {
        let om = this._max;
        this._max = Number.isNaN(m) ? this._max : m;
        this.health *= this._max / om;
        return this._max;
    }

    damage(v) {
        if (this.inv || !v) return;
        this.health -= v;
        this.lD = Date.now();
        addPoof(...this.owner.sca());
    }

    heal(v) {
        if (this.health >= this.max) return;
        this.health += v;
        this.lH = Date.now();
    }

    get health() {
        return this._h;
    }

    set health(h) {
        this._h = Number.isNaN(h) ? this._h : Math.min(Math.max(-Infinity, h), this.max);
        return this._h;
    }

    get dead() {
        return this.health <= 0;
    }
}
class Stats {
    constructor() {
        this.dmg = 15;
        this.prc = 2;
    }
}
class Block {
    constructor(x = 0, y = 0, w = 100, h = 100) {
        this.x = x;
        this.y = y;
        this.w = w;
        this.h = h;
    }

    get lines() {
        return [
            [this.x, this.y, this.x, this.y + this.h],
            [this.x + this.w, this.y, this.x + this.w, this.y + this.h],
            [this.x, this.y, this.x + this.w, this.y],
            [this.x, this.y + this.h, this.x + this.w, this.y + this.h]
        ];
    }
}
class Entity {
    constructor(x = 0, y = 0, t = function () { }, p = false, mh = 100) {
        this.x = x;
        this.y = y;
        this.tick = t;
        this.isPlayer = p;
        this.health = new Health(mh, p ? "#0d4" : "#d04", this);
        this._tg = [0, 0];
        this.radius = 36;
        this.stats = new Stats;
        this.isHostile = !p;
        this.hasGun = !!p;
        this.collides = true;
        this.styles = {};
        this.lastMove = 0;
        this.lastShot = 0;
        this.canHit = true;
    }

    get isPooper() {
        return this.health.color === "#d04";
    }

    get isInRoom() {
        return Math.abs(this.x) < 425 && Math.abs(this.y) < 425;
    }

    get tsls() {
        return mainGame.now - this.lastShot;
    }

    ondeath() { }
    oninteract() { }

    giveSprite(src) {
        this.src = src;
    }

    get tg() {
        return this.isPlayer ? Math.s2w(mainGame.mouse.x, mainGame.mouse.y) : this._tg;
    }

    angle(oe = new Entity) {
        return Math.ang(this.x, this.y, oe.x, oe.y);
    }

    contact(oe = new Entity) {
        return this.dist(oe) <= oe.radius + this.radius;
    }

    dist(oe = new Entity) {
        return Math.hypot(this.x - oe.x, this.y - oe.y);
    }

    get playerContact() {
        return !this.isPlayer && this.contact(mainGame.player);
    }

    die() {
        const self = this;
        mainGame.entities = mainGame.entities.filter(function (t) {
            return t !== self;
        });
        this.health.lD = Date.now();
        this.ondeath();
        this.health.health = -1e6;
        if (this.isPartyGoer) {
            mainGame.popo = 1;
            mainGame.player.die();
        }
    }

    cl(oe = new Block) {
        if (oe instanceof Entity) return this.contact(oe) && this.x > oe.x;
        if (this.x > oe.x + oe.w && this.y - this.radius < oe.y + oe.h && this.y + this.radius > oe.y && this.x - (oe.x + oe.w) < this.radius) {
            if (this.y >= oe.y) {
                if (this.y > oe.y + oe.h) {
                    let a2a = Math.ang(this.x, this.y, oe.x + oe.w, oe.y + oe.h);
                    let d1a = -Math.circ(this.radius, a2a, 0, 0)[0];
                    if (this.x - (oe.x + oe.w) < d1a) return oe.x + oe.w - (this.x - d1a);
                }
                return oe.x + oe.w - (this.x - this.radius);
            }
            let a2b = Math.ang(this.x, this.y, oe.x + oe.w, oe.y);
            let d1b = -Math.circ(this.radius, a2b, 0, 0)[0];
            if (this.x - (oe.x + oe.w) < d1b) return oe.x + oe.w - (this.x - d1b);
        }
    }

    cr(oe = new Block) {
        if (oe instanceof Entity) return this.contact(oe) && this.x < oe.x;
        if (this.x < oe.x && this.y - this.radius < oe.y + oe.h && this.y + this.radius > oe.y && oe.x - this.x < this.radius) {
            if (this.y >= oe.y) {
                if (this.y > oe.y + oe.h) {
                    let a2a = Math.ang(this.x, this.y, oe.x, oe.y + oe.h);
                    let d1a = Math.circ(this.radius, a2a, 0, 0)[0];
                    if (oe.x - this.x < d1a) return this.x + d1a - oe.x;
                }
                return this.x + this.radius - oe.x;
            }
            let a2b = Math.ang(this.x, this.y, oe.x, oe.y);
            let d1b = Math.circ(this.radius, a2b, 0, 0)[0];
            if (oe.x - this.x < d1b) return this.x + d1b - oe.x;
        }
    }

    cu(oe = new Block) {
        if (oe instanceof Entity) return this.contact(oe) && this.y > oe.y;
        if (this.y > oe.y + oe.h && this.x - this.radius < oe.x + oe.w && this.x + this.radius > oe.x && this.y - (oe.y + oe.h) < this.radius) {
            if (this.x >= oe.x) {
                if (this.x > oe.x + oe.w) {
                    let a2a = Math.ang(this.y, this.x, oe.y + oe.h, oe.x + oe.w);
                    let d1a = -Math.circ(this.radius, a2a, 0, 0)[0];
                    if (this.y - (oe.y + oe.h) < d1a) return oe.y + oe.h - (this.y - d1a);
                }
                return oe.y + oe.h - (this.y - this.radius);
            }
            let a2b = Math.ang(this.y, this.x, oe.y + oe.h, oe.x);
            let d1b = -Math.circ(this.radius, a2b, 0, 0)[0];
            if (this.y - (oe.y + oe.h) < d1b) return oe.y + oe.h - (this.y - d1b);
        }
    }

    cd(oe = new Block) {
        if (oe instanceof Entity) return this.contact(oe) && this.y < oe.y;
        if (this.y < oe.y && this.x - this.radius < oe.x + oe.w && this.x + this.radius > oe.x && oe.y - this.y < this.radius) {
            if (this.x >= oe.x) {
                if (this.x > oe.x + oe.w) {
                    let a2a = Math.ang(this.y, this.x, oe.y, oe.x + oe.w);
                    let d1a = Math.circ(this.radius, a2a, 0, 0)[0];
                    if (oe.y - this.y < d1a) return this.y + d1a - oe.y;
                }
                return this.y + this.radius - oe.y;
            }
            let a2b = Math.ang(this.y, this.x, oe.y, oe.x);
            let d1b = Math.circ(this.radius, a2b, 0, 0)[0];
            if (oe.y - this.y < d1b) return this.y + d1b - oe.y;
        }
    }

    collide(oe = new Entity) {
        if (!oe.collides || !oe.canHit) return;
        let v1a = [this.x - oe.x, this.y - oe.y];
        let cd = this.dist(oe);
        let nd = this.radius + oe.radius - 1;
        let v1b = oe.sca();
        let v1c = v1a.map((v, i) => v1b[i] + v * nd / cd);
        //Object.assign(this, v1c.acj());
    }

    update() {
        if (this.pizza && this.pizza.attach !== this) delete this.pizza;
        if (this.pot && this.pot.attach !== this) delete this.pizza;
        if (this.isPartyGoer && !this.isInRoom) {
            this.x *= 0.9;
            this.y *= 0.9;
        }
        if (this.isPlayer) {
            let fun = mainGame.funLevel._h;
            if (this.isPooper) {
                if (fun <= 1 / 4) this.health.health += 1 / 50;
                if (fun >= 3 / 4) this.health.health -= 1 / 50;
            }
            else {
                if (fun <= 1 / 4) this.health.health -= 1 / 50;
                if (fun >= 3 / 4) this.health.health += 1 / 50;
            }
        }
        if (!this.src) this.radius = [36, 48][Number(this.hasGun)];
        this.tick();
        if (this.health.dead) this.die();
        if (this.playerContact && this.isHostile && !this.hasGun) mainGame.player.health.damage(this.stats.dmg);
        if ((this.hasGun) && mainGame.now - this.health.lH > 1000) this.health.heal(1);
    }

    isInLineOfSight(o1 = mainGame.player) {
        return this !== o1 && !this.health.inv;
        //return this.dist(o1) < 384 + this.radius * 2 && this !== o1 && !this.health.inv;
        //let a1a = Math.ang(o1.x, o1.y, o2.x, o2.y);
        //let a1b = o1.angle(this);
        //return Math.abs(a1a - a1b) <= 45 && this.dist(o1) < 384 + 48 && !this.isPlayer;
    }

    isInLineOfFire(o1 = mainGame.player, o2 = Math.s2w(mainGame.mouse.x, mainGame.mouse.y), d4a = getWindowDiag() + this.radius) {
        let o3 = o2;
        let ep = Math.d2l(o1.x, o1.y, ...o3, this.x, this.y);
        return o1 !== this &&
            this.dist(o1) < d4a &&
            (Math.hypot(this.x - o1.x, this.y - o1.y) <= this.radius ||
            (Math.hypot(this.x - ep[0], this.y - ep[1]) <= this.radius && Math.ln_int(this.x, this.y, ...ep, o1.x, o1.y, ...o3).seg2) ||
            Math.hypot(o3[0] - this.x, o3[1] - this.y) <= this.radius);
    }

    fire() {
        this.lastShot = mainGame.now;
        let d5a = Infinity;
        for (let b = 0; b < mainGame.blocks.length; b++) {
            let lns = mainGame.blocks[b].lines.map(l => {
                let ret = Math.ln_int(this.x, this.y, this.tg[0], this.tg[1], ...l, getWindowDiag()).dist;
                return ret ? ret : Infinity;
            });
            d5a = Math.min(d5a, ...lns);
        }
        let targets = [];
        let eee = mainGame.AE_;
        if (this.tg) for (let e = 0; e < eee.length; e++) {
            if (eee[e].isInLineOfFire(this, this.tg, d5a)) targets.push(eee[e]);
        }
        targets = targets.filter(c => c.collides).sort((function (a, b) {
            if (this.dist(a) < this.dist(b)) return -1;
            return 1;
        }).bind(this)).slice(0, this.stats.prc + 1);
        for (let t = 0; t < targets.length; t++) {
            targets[t].health.damage(this.stats.dmg);
        }
        playSound(gunShot.default);
    }

    interact(filter = i => !!i) {
        const self = this;
        let i2a = mainGame.entities.filter(n => n.src && n.dist(self) < 128 && (n.collides || n.isPizza) && filter(n));
        i2a.forEach(m => m.oninteract(self));
    }

    get color() {
        if (this.partyMode) return ({
            pizza: "#200000",
            dance: "#000020"
        })[this.partyMode];
        return Date.now() - this.health.lD <= 25 ? "white" : Date.now() - this.health.lH <= 25 ? "green" : "black";
    }

    move(x, y) {
        let ret = false;
        if (x) {
            this.x += x;
            if (x < 0) for (let b = 0; b < mainGame.blocks.length; b++) {
                let l = this.cl(mainGame.blocks[b]);
                if (l) this.x += l;
                ret = ret || l;
            }
            else for (let b = 0; b < mainGame.blocks.length; b++) {
                let l = this.cr(mainGame.blocks[b]);
                if (l) this.x -= l;
                ret = ret || l;
            }
        }
        if (y) {
            this.y += y;
            if (y < 0) for (let b = 0; b < mainGame.blocks.length; b++) {
                let l = this.cu(mainGame.blocks[b]);
                if (l) this.y += l;
                ret = ret || l;
            }
            else for (let b = 0; b < mainGame.blocks.length; b++) {
                let l = this.cd(mainGame.blocks[b]);
                if (l) this.y -= l;
                ret = ret || l;
            }
        }
        let aee = mainGame.AE_;
        for (let e1a = 0; e1a < aee.length; e1a++) {
            let tm = aee[e1a];
            if (tm.contact(this) && tm.dist(this)) {
                this.collide(tm);
                ret = true;
            }
        }
        if (this.isPlayer && mainGame.isTutorial && tutorialStage === 1) tutorialStage++;
        if (!ret && Date.now() - this.lastMove > 423 && (this.hasGun || this.isPlayer)) {
            playSound(stepNow.default, 1 / 4);
            this.lastMove = Date.now();
        }
        return ret;
    }
}
function getWindowDiag() {
    return Math.hypot(window.innerHeight, window.innerWidth) / 2;
}
let tutorialEnemy;
let tutorialStage = 0;
const tutWorld = new World("#0060a5");
const mainGame = {
    findRandomPizza() {
        return this.entities.filter(f => f.isPizza).rand();
    },
    popo: 0,
    get sound() {
        switch (this.musicTheme) {
            default:
            case 0:
                return [{
                    src: trapNorm.default,
                    vol: 1 - this.loudness
                }, {
                    src: trapDist.default,
                    vol: this.loudness
                }];
                break;
            case 1:
                return [{
                    src: rockDist.default,
                    vol: 1 - this.loudness
                }, {
                    src: rockNorm.default,
                    vol: this.loudness
                }];
                break;
        }
    },
    musicTheme: 0,
    _loudness: 0,
    get loudness() {
        return this._loudness;
    },
    set loudness(l) {
        this._loudness = Math.clamp(l, 0, 1);
        let norm = document.getElementById("OSTNorm");
        let dist = document.getElementById("OSTDist");
        if (norm) norm.volume = Math.clamp(1 - l, 0, 1);
        if (dist) dist.volume = Math.clamp(l, 0, 1);
    },
    posNoise: 0,
    player: new Entity(0, -300, function () {
        if (keys.a || keys.ArrowLeft) this.move(-4, 0);
        if (keys.d || keys.ArrowRight) this.move(4, 0);
        if (keys.s || keys.ArrowDown) this.move(0, 4);
        if (keys.w || keys.ArrowUp) this.move(0, -4);
    }, true),
    get guard() {
        return this.entities.find(e => e.hasGun);
    },
    get djBoard() {
        return this.entities.find(e => e.isDJBoard);
    },
    get speeka() {
        return this.entities.find(e => e.isSpeaker);
    },
    entities: [],
    blocks: [],
    score: 0,
    highScores: localStorage.party_patrol_hi_scores_2023 ? JSON.parse(localStorage.party_patrol_hi_scores_2023) : [0, 0, 0],
    get isPaused() {
        return this.hasMainMenu || this.player.health.dead || pausepage;
    },
    lastTipRotation: Date.now(),
    update() {
        if (Date.now() - this.lastTipRotation > 5000) {
            this.lastTipRotation = Date.now();
            tipIndex = (tipIndex + 1) % tips.length;
        }
        this.posNoise = this.loudness ** 2 * 98 + 2;
        _ll = (_ll * 9 + trgBrightness) / 10;
        this.lft = Date.now() - this.lrt;
        this.lrt = Date.now();
        if (!this.isPaused) {
            this.now += this.lft;
            this.score++;
            if (this.score > this.highScores[this.mode] && !this.hasBeatenHiScore) {
                this.hasBeatenHiScore = true;
                addMessage("You have beaten your high score!");
            }
        }
        else return;
        if (this.loudness) {
            if (this.popo >= 0.99) mainGame.player.die();
            else this.popo = Math.min(0.99, this.popo + 0.001);
        }
        else this.popo = Math.max(0, this.popo - 0.01);
        this.AE_.forEach(a => a.update());
        if (this.isTutorial) this.doTutorial();
        else if (this.mode === 1) this.tickSpawn();
    },
    spawnRate: 1,
    tickSpawn() {
        if (Math.chance(0.999 / this.spawnRate) || this.entities.length > 500) return;
        let targD = Math.chance(0.6) ? mainGame.findRandomPizza() : Math.chance(0.5) ? mainGame.djBoard : mainGame.speeka;
        let ret = this.addEntity(Math.rbtw(-900, 900), Math.rbtw(-900, -600), function () {
            if (this.pizza) this._tg = Math.circ(18, Math.ang(0, 0, ...this.sca()), ...this.sca());
            if (this.health.health < this.health.max && !this.done) {
                this.done = true;
            }
            if (this.done || this.didIt) {
                if (!this.pizza || this.isInRoom) this.move(this.x / -10, 10);
                else this.move(this.x / 75, 10);
                if (this.isOffScreen && !mainGame.loudness) this.health.health = 0;
            }
            else {
                if (this.isInRoom) {
                    this.move(...Math.circ(3, this.angle(targD), 0, 0));
                    if (targD.contact(this) && !this.didIt && !mainGame.loudness) {
                        this.interact(s => s === targD);
                        this.didIt = true;
                    }
                }
                else {
                    if (this.y < 0) this.move(3 * this.x / Math.abs(this.x), 3);
                    else if (Math.abs(this.x) < 100) this.move(0, -3);
                    else this.move(-3 * this.x / Math.abs(this.x), 1.5);
                }
            }
        });
        ret.ondeath = function () {
            if (!this.isOffScreen) {
                mainGame.popo = 1;
                mainGame.player.die();
            }
            else if (!this.hasRewarded && Date.now() - this.health.lD < 10000) {
                mainGame.score += 1000;
                this.hasRewarded = true;
            }
        }
        ret.stats.dmg = 0.1;
    },
    doTutorial() {
        switch (tutorialStage) {
            case 0:
                addMessage("You can use WASD or the\narrow keys to move");
                tutorialStage++;
                break;
            case 2:
                tutorialEnemy = this.addEntity(Math.rbtw(-400, 400), Math.rbtw(-400, 400), function () {
                    let d6c = 4;
                    if (this.health.health < this.health.max && !this.done) {
                        this.done = true;
                        tutorialStage++;
                    }
                    if (this.done) {
                        if (Math.abs(this.x) < 500 && Math.abs(this.y) < 500) {
                            this.move(this.x * -0.1, 10);
                            d6c = 0;
                        }
                        else d6c = 10;
                        if (this.isOffScreen) this.health.health = 0;
                    }
                    let d6a = this.dist(mainGame.player);
                    let d6b = d6a + d6c;
                    let v3a = [this.x - mainGame.player.x, this.y - mainGame.player.y];
                    let v3b = [mainGame.player.x + v3a[0] * d6b / d6a - this.x, mainGame.player.y + v3a[1] * d6b / d6a - this.y];
                    this.move(...v3b);
                });
                tutorialEnemy.ondeath = function () {
                    if (!this.isOffScreen) addMessage("You mustn't attract the police to\nthe loud party by killing\nthe party poopers.");
                }
                tutorialEnemy.stats.dmg = 0.1;
                addMessage("A party pooper has spawned.\nAs the security guard for the party,\nit is your job to make sure it\ndoes not cause any trouble on the party floor.\nMove the mouse around to aim\nat it (aiming line must go directly\nthrough it) and click to taze it.\nHit it to continue, but don't\nhit anyone else.");
                tutorialStage++;
                this.score += 1000;
                break;
            case 4:
                addMessage("Sometimes, you will play as a party pooper.\nIn this mode, you will evade the guard.\nClick anywhere to continue.");
                tutorialStage++;
                this.score += 1000;
                break;
            case 6:
                addMessage("Right click anywhere to interact\nwith objects that are within range.\nPick up a pot from outside to move on.");
                tutorialStage++;
                this.score += 1000;
                break;
            case 8:
                this.becomePooper();
                addMessage("A guard has spawned near the inner entrance!\nHe will be after you. You can\nclick while holding a pot to throw it.\nHit the guard to stun him.");
                tutorialStage++;
                this.score += 1000;
                break;
            case 10:
                addMessage("Don't kill the guard either,\notherwise the police will come\nand shut down the party.\nSame if anybody dies, including you.\nClick anywhere to continue.");
                tutorialStage++;
                this.score += 1000;
                break;
            case 12:
                addMessage("As a party pooper, you can\ncause trouble and lower the fun level meter.\nIf it stays above 75%, you will start\nto lose health. If it's below 25%,\nyou will heal.\nWorks the opposite way as the guard.\nClick anywhere to continue.");
                tutorialStage++;
                this.score += 1000;
                break;
            case 14:
                addMessage("One way to cause trouble is to steal pizza\nfrom pizza lovers (colored slightly red).\nNeutral partygoers will not care.\nRight click near a pizza to take it,\njust like with the pots.");
                tutorialStage++;
                this.score += 1000;
                break;
            case 16:
                addMessage("The pizza lovers will chase you down,\nso you'd better hide it outside.\nPut it down outside to continue.");
                tutorialStage++;
                this.score += 1000;
                break;
            case 18:
                addMessage("You can also cause trouble by changing\nthe music. Interact with the DJ\nboard in the corner to do so and continue.");
                tutorialStage++;
                this.score += 1000;
                break;
            case 20:
                addMessage("The guard will come to turn it back.\nAt first he will somewhat ignore you,\nbut once he takes care of the music he\nwill chase you down. Escape\nand click anywhere to continue.");
                tutorialStage++;
                this.score += 1000;
                break;
            case 22:
                addMessage("One more thing (don't do this as a pooper, it\nwill be game over if you do): Sometimes, party poopers will\nturn the music way up via the speaker in\nthe other corner. You have to turn it\nback down as the guard. Click anywhere to continue.");
                tutorialStage++;
                this.score += 1000;
                break;
            case 24:
                this.becomeGuard();
                tutorialEnemy = this.addEntity(10, -750, function () {
                    if (this.health.health < this.health.max && !this.done && this.didIt) {
                        this.done = true;
                        tutorialStage++;
                    }
                    if (this.done) {
                        if (Math.abs(this.x) < 500 && Math.abs(this.y) < 500) this.move(this.x * -0.1, 10);
                        else this.move(this.x / 10, 10);
                        if (this.isOffScreen && !mainGame.loudness) this.health.health = 0;
                    }
                    else {
                        let targD = mainGame.speeka;
                        if (this.isInRoom) {
                            this.move(...Math.circ(3, this.angle(targD), 0, 0));
                            if (targD.contact(this) && !this.didIt) {
                                this.interact(s => s.isSpeaker);
                                this.didIt = true;
                            }
                        }
                        else {
                            if (this.y < 0) this.move(3 * this.x / Math.abs(this.x), 3);
                            else if (Math.abs(this.x) < 100) this.move(0, -3);
                            else this.move(-3 * this.x / Math.abs(this.x), 1.5);
                        }
                    }
                });
                tutorialEnemy.stats.dmg = 0.1;
                addMessage("You are the guard again. Turn the music back down\nwhen the pooper comes and hit him\nto continue.");
                tutorialStage++;
                this.score += 1000;
                break;
            case 26:
                addMessage("Do the same as the guard when\nthe poopers change the music.\nYou have now completed the tutorial.\nYou can press 'Esc' to pause\nand return to the main menu from there.");
                tutorialStage++;
                this.score += 1000;
                break;
            default:
                break;
        }
    },
    now: Date.now(),
    becomePooper() {
        this.player.health.color = "#d04";
        this.player.hasGun = false;
        this.player.health.max = 100;
        gameUtil.addGuard(0, 500, mainGame);
    },
    becomeGuard() {
        if (this.guard) this.guard.die();
        this.player.health.color = "#0d4";
        this.player.hasGun = true;
        this.player.health.max = 150;
        if (this.player.pizza) {
            this.player.pizza.die();
            delete this.player.pizza;
        }
        if (this.player.pot) {
            this.player.pot.die();
            delete this.player.pot;
        }
    },
    reset() {
        this.highScores[this.mode] = Math.max(this.score, this.highScores[this.mode]);
        localStorage.setItem("party_patrol_hi_scores_2023", JSON.stringify(this.highScores));
        this.score = 0;
        this.spawnRate = 1;
        this.hasBeatenHiScore = false;
        this.hasMainMenu = true;
        Menu.change(MainMenu);
        this.isTutorial = false;
        tutorialEnemy = null;
        tutorialStage = 0;
        msg = "";
        lastMsg = 0;
        pauseMessage = "Paused";
        this.blocks = [];
        this.entities = [];
        this.player.hasGun = true;
        this.player.x = 0;
        this.player.y = -300;
        this.player.health.health = Infinity;
        this.musicTheme = 0;
        this.preferredMusic = 0;
        this.popo = 0;
        this.loudness = false;
        this.numOfPeople = 5;
        setBgColor("#0060a5");
    },
    hasBeatenHiScore: false,
    go(w = new World) {
        w.execute();
        this.blocks = [...w.b];
        this.entities = [...w.entities];
        setBgColor(w.c);
        gameUtil.addSubWoofa(this);
        gameUtil.addDJBoard(this);
        for (let i = 0; i < this.numOfPeople; i++) gameUtil.addPartyGoer(Math.rbtw(50, 300), Math.rbtw(-300, 300), 0, this);
        for (let i = 0; i < this.numOfPeople; i++) gameUtil.addPizzaLover(Math.rbtw(-300, -50), Math.rbtw(-300, 300), this);
        for (let i = 0; i < this.numOfPeople; i++) gameUtil.addDancePerson(Math.rbtw(-300, -50), Math.rbtw(-300, 300), this);
    },
    numOfPeople: 5,
    preferredMusic: 0,
    addEntity(x = 0, y = 0, t = function () { }) {
        let e = new Entity(x, y, t);
        this.entities.push(e);
        return e;
    },
    get AE_() {
        return [...this.entities, this.player];
    },
    get _ae2() {
        return this.entities.filter(t => !t.hasGun);
    },
    mouse: _ms,
    lrt: 0,
    lft: 0,
    mode: 0,
    start(md) {
        this.hasMainMenu = false;
        this.mode = md;
        this.isTutorial = !md;
        if (this.isTutorial) {
            this.go(tutWorld);
            this.becomeGuard();
        }
        else if (md === 1) {
            this.go(tutWorld);
            this.becomeGuard();
        }
        else if (md === 2) {
            this.go(tutWorld);
            this.becomePooper();
        }
    },
    hasMainMenu: true,
    isTutorial: false,
    get funLevel() {
        let ret = new Health(1, "#40d");
        ret.health = Math.avg(...this.entities.filter(e => (typeof e.funLevel !== "undefined")).map(r => r.funLevel));
        return ret;
    }
};
mainGame.player.ondeath = function () {
    pauseMessage = mainGame.popo ? mainGame.loudness ? "Game over - The po-po shut down the loud party" : "Game over - The po-po got you - no killing allowed" : "Game over - You got gotten";
};
let numberOfMusicThemes = 2;
const gameUtil = {
    addDJBoard(t) {
        let p = mainGame.addEntity.apply(t, [200, -400, () => { }]);
        p.giveSprite(dJBoard.default);
        p.stats.dmg = 0;
        p.isDJBoard = true;
        p.isHostile = false;
        p.health.inv = true;
        p.keepDimensions = true;
        p.radius = 72;
        p.text = "Change music theme";
        p.canHit = false;
        p.oninteract = function () {
            if (tutorialStage === 19) tutorialStage++;
            mainGame.musicTheme = (mainGame.musicTheme + 1) % numberOfMusicThemes;
        };
        return p;
    },
    addSubWoofa(t) {
        let p = mainGame.addEntity.apply(t, [-200, -436, function () {
            if (this.isLoud) mainGame.loudness += 0.01;
            else mainGame.loudness -= 0.01;
        }]);
        p.giveSprite(speeKA.default);
        p.stats.dmg = 0;
        p.isSpeaker = true;
        p.isHostile = false;
        p.health.inv = true;
        p.keepDimensions = true;
        p.radius = 72;
        p.text = "Change volume";
        p.canHit = false;
        p.oninteract = function () {
            this.isLoud = !this.isLoud;
        };
        return p;
    },
    addPizza(x, y, t) {
        let p = mainGame.addEntity.apply(t, [x, y, function () {
            if (this.attach) {
                let a = Math.ang(...this.attach.sca(), ...this.attach.tg);
                Object.assign(this, Math.circ(18 + this.attach.radius, a, ...this.attach.sca()).acj());
                this.styles.transform = `rotateZ(${a}deg)`;
                this.a4a = a;
            }
            else {
                delete this.styles.transform;
            }
        }]);
        p.oninteract = function (t) {
            if (tutorialStage === 15 && mainGame.isTutorial) tutorialStage++;
            if (!t.lastPizzaHeal || mainGame.now - t.lastPizzaHeal > 10000) {
                t.health.heal(15);
                if (t.isPlayer && !mainGame.isTutorial) addMessage("You have been healed by the power of pizza.")
                t.lastPizzaHeal = mainGame.now;
            }
            else if (t.lastPizzaHeal && t.isPlayer && !mainGame.isTutorial) addMessage(`Pizza healing via pizza will be\navailible in another ${10 - Math.floor((mainGame.now - t.lastPizzaHeal) / 1000)}s`);
            if (t.pot || t.pizza) return;// (t.isPlayer && t !== this.attach) ? addMessage("You are already holding something.") : null;
            if (this.attach) {
                if (this.attach.isPlayer) handleMouseDown({ button: 0, cantSkip: true });
                else delete this.attach.pizza;
            }
            this.attach = t;
            t.pizza = this;
            this.lastGun = t.hasGun;
            t.hasGun = false;
            playSound(pickUp.default);
        }
        Object.defineProperty(p, 'text', {
            get() {
                return mainGame.player.hasGun ? "" : "Take";
            }
        });
        p.isPizza = true;
        p.health.max = 1;
        p.radius = 36;
        p.isHostile = false;
        p.giveSprite(piZZZa.default);
        p.health.inv = true;
        p.collides = false;
        return p;
    },
    addPot(x, y, t) {
        let p = mainGame.addEntity.apply(t, [x, y, function () {
            if (this.dist(mainGame.player) < 128 && !this.attach) this.giveSprite(pot1Copy.default);
            else this.giveSprite(pot1.default);
            if (this.attach) {
                this.x = this.attach.x;
                this.y = this.attach.y - 18 - this.attach.radius;
                let a = Math.ang(...this.attach.sca(), ...this.attach.tg);
                this.styles.transform = `rotateZ(${a}deg)`;
                this.styles.transformOrigin = `50% ${this.radius + 18 + this.attach.radius}px`;
                this.a4a = a;
            }
            else if (this.a4a) {
                delete this.styles.transformOrigin;
                if (this.move(...Math.circ(18, this.a4a, 0, 0))) this.die();
                this.health.inv = false;
                for (let e = 0; e < mainGame.entities.length; e++) {
                    let n = mainGame.entities[e];
                    if (this.contact(n) && n !== this && !n.isPlayer) {
                        if (n.hasGun) {
                            n.isStunned = n.isStunned ? n.isStunned + 1 : 1;
                            setTimeout(function () {
                                n.isStunned--;
                            }, 5000);
                        }
                        n.health.damage(8);
                        this.die();
                    }
                }
            }
        }]);
        p.ondeath = function () {
            playSound(breakPot.default);
        }
        p.oninteract = function (t) {
            if ((t.pot || t.pizza) && t.isPlayer) return;// addMessage("You are already holding something.");
            delete this.a4a;
            this.health.inv = true;
            this.attach = t;
            t.pot = this;
            this.collides = false;
            this.lastGun = t.hasGun;
            t.hasGun = false;
            if (tutorialStage === 7 && mainGame.isTutorial) tutorialStage++;
            playSound(pickUp.default);
        }
        Object.defineProperty(p, 'text', {
            get() {
                return mainGame.player.hasGun ? "" : "Take";
            }
        });
        p.health.max = 1;
        p.radius = 36;
        p.isHostile = false;
        p.giveSprite(pot1.default);
        p.health.inv = true;
        return p;
    },
    addPartyGoer(x, y, m = 0, t = mainGame) {
        let p = mainGame.addEntity.apply(t, [x, y, function () {
            if (!this.mode && this.x >= 0 && this.x <= 300) for (let p = 0; p < mainGame.entities.length; p++) {
                let z = mainGame.entities[p];
                if (z.isPizza && this.dist(z) < 128 && (!z.attach || z.attach.isPlayer)) {
                    this.interact();
                    this.mode = 1;
                    delete this.dir;
                }
            }
            switch (this.mode) {
                default:
                case 1:
                    if (this.x > -300 || !this.hitAlready) {
                        this.hitAlready = this.move(-4, 0);
                        this._tg[1] = this.y;
                        this._tg[0] = -300;
                    }
                    else {
                        this._tg[0] = this.x;
                        this._tg[1] = -600;
                    }
                    if (!this.pizza) this.mode = 0;
                    break;
                case 0:
                    if (!this.dir || this.dir === "r") {
                        this.move(4, 0);
                        if (this.x > 300) this.dir = "l";
                    }
                    else {
                        this.move(-4, 0);
                        if (this.x < 0) this.dir = "r";
                    }
                    this.move(0, Math.rmid());
                    break;
            }
        }]);
        p.hasGun = false;
        p.isPartyGoer = true;
        p.health.color = "#0d4";
        p.health.max = 1;
        p.mode = m;
        p.dir = null;
        p.isHostile = false;
        p.stats.dmg = 0;
        p.funLevel = Math.random();
        return p;
    },
    addDancePerson(x, y, t = mainGame) {
        let p = mainGame.addEntity.apply(t, [x, y, function () {
            if (mainGame.musicTheme === mainGame.preferredMusic) {
                if (!this.dir || this.dir === "r") {
                    this.move(4, 0);
                    if (this.x > 300) this.dir = "l";
                }
                else {
                    this.move(-4, 0);
                    if (this.x < 0) this.dir = "r";
                }
                this.move(0, Math.rmid());
                this.funLevel = 1 - (1 - this.funLevel) * 0.9;
            } else {
                this.funLevel *= 0.9;
            }
        }]);
        p.hasGun = false;
        p.isPartyGoer = true;
        p.health.color = "#0d4";
        p.health.max = 1;
        p.dir = null;
        p.isHostile = false;
        p.stats.dmg = 0;
        p.funLevel = 0.5;
        p.partyMode = "dance";
        return p;
    },
    addPizzaLover(x, y, t = mainGame) {
        let p = mainGame.addEntity.apply(t, [x, y, function () {
            this.pza++;
            this._tg = Math.circ(18, this.pza, ...this.sca());
            if (!this.pizza) {
                this.funLevel *= 0.9;
                const self = this;
                if (this.myPizza.isInRoom || (this.myPizza.attach && this.myPizza.attach.isInRoom)) {
                    this.move(...Math.circ(3, this.angle(this.myPizza), 0, 0));
                    if (this.contact(this.myPizza) && mainGame.now - this.lastLoss > 1000) {
                        this.interact(x2 => x2 === self.myPizza);
                        delete this.lastLoss;
                    }
                }
                else {
                    if (tutorialStage === 17) tutorialStage++;
                    this.move(...Math.circ(3, this.pza, 0, 0));
                    if (mainGame.mode === 2 && !mainGame.isTutorial) mainGame.score++;
                }
                if (!this.lastLoss || mainGame.now - this.lastLoss > 60000) {
                    if (this.lastLoss) {
                        this.myPizza = gameUtil.addPizza(this.x, this.y, mainGame);
                        this.interact(x2 => x2 === self.myPizza);
                    }
                    this.lastLoss = mainGame.now;
                }
            }
            else {
                let d2t = this.targX - this.x;
                if (Math.abs(d2t) > 3) {
                    this.move(4 * d2t / Math.abs(d2t));
                }
                this.funLevel = 1 - (1 - this.funLevel) * 0.9;
            }
        }]);
        p.targX = x;
        p.hasGun = false;
        p.isPartyGoer = true;
        p.health.color = "#0d4";
        p.health.max = 1;
        p.isHostile = false;
        p.stats.dmg = 0;
        p.funLevel = 1;
        p.pza = Math.rbtw(-180, 180);
        let z = this.addPizza(x, y, t);
        p.myPizza = z;
        p.interact(x1 => x1 === p.myPizza);
        p.partyMode = "pizza";
        return p;
    },
    addGuard(x, y, t) {
        let p = mainGame.addEntity.apply(t, [x, y, function () {
            if (this.health.health < this.health.max && tutorialStage === 9) tutorialStage++;
            if (this.isStunned) return;
            if (mainGame.musicTheme === mainGame.preferredMusic) {
                let d7a = mainGame.player.dist(this);
                let sca1 = this.sca();
                if (d7a < 512) {
                    this.follow[0] = true;
                    this.follow[1] = this.angle(mainGame.player);
                    this.inside = false;
                }
                else {
                    this.follow[0] = false;
                    this.follow[1] = Math.ang(0, 0, ...sca1) - 90;
                    this.inside = false;
                    this._tg = Math.circ(24, this.follow[1], ...sca1);
                }
                if (d7a > 256 && !this.hasLock) {
                    if (d7a < 432) this.move(...Math.circ(5, this.follow[1], 0, 0));
                    else this.move(...Math.circ(2.5, this.follow[1], 0, 0));
                }
                else {
                    this._tg = Math.aav(mainGame.player.sca(), this._tg, this._tg, this._tg, this._tg, this._tg, this._tg, this._tg);
                    this.hasLock = true;
                    if (d7a > 384) delete this.hasLock;
                }
            }
            else {
                let targD = mainGame.djBoard;
                if (this.isInRoom) {
                    this.move(...Math.circ(3, this.angle(targD), 0, 0));
                    if (targD.contact(this)) this.interact(i => i.isDJBoard);
                }
                else {
                    if (this.y < 0) this.move(3 * this.x / Math.abs(this.x), 3);
                    else if (Math.abs(this.x) < 100) this.move(0, -3);
                    else this.move(-3 * this.x / Math.abs(this.x), 1.5);
                }
                this._tg = targD.sca();
            }
            let canShoot = this.tsls > 500 && mainGame.player.isInLineOfFire(this, this.tg);
            if (canShoot) for (let g = 0; g < mainGame.entities.length; g++) {
                let goer = mainGame.entities[g];
                if (goer.isInLineOfFire(this, this.tg) && goer.isPartyGoer) {
                    canShoot = false;
                    break;
                }
            }
            if (canShoot) this.fire();
        }]);
        p.hasGun = true;
        p.health.color = "#0d4";
        p.follow = [false, 180];
        p.inside = false;
        p.ondeath = function () {
            if (mainGame.isTutorial) return;
            mainGame.popo = 1;
            mainGame.player.die();
        };
        return p;
    }
};
let allPoofs = [];
function Poof({ poof }) {
    const styles = {
        padding: 64,
        backgroundColor: "wheat",
        display: "inline-block",
        borderRadius: 64,
        ...Math.w2s(...poof.sca(), 64).acj().css(),
        position: "absolute"
    };
    return <div style={styles} className="poof-anim"></div>;
}
tutWorld._exec = function () {
    /// nothing here
};
function addPoof(x, y) {
    let vv = {
        x: x,
        y: y,
        t: Date.now()
    };
    allPoofs.push(vv);
    setTimeout(function () {
        allPoofs = allPoofs.filter(p => p !== vv);
    }, 495);
}
function MainMenu() {
    const s1a = {
        textAlign: "center",
        background: "black radial-gradient(circle at center, var(--FLOORCOLOR) 0px, transparent 512px)",
        height: window.innerHeight - 200,
        padding: 100
    };
    const s1b = {
        backgroundColor: "rgba(0,0,0,0.8)",
        height: "100%",
        overflow: "auto"
    };
    const c1a = function () {
        mainGame.start(0);
    };
    const c1b = function () {
        Menu.change(SecureMenu);
    };
    const c1c = function () {
        Menu.change(PooperMenu);
    };
    return <div style={s1a}>
        <div style={s1b}>
            <div className="menu-option" onClick={c1a}>Play Tutorial</div>
            <div className="menu-option" onClick={c1b}>Play Game As A Security Guard<br />High Score: {Math.rdv(mainGame.highScores[1])}</div>
            <div className="menu-option" onClick={c1c}>Play Game As A Party Pooper<br />High Score: {Math.rdv(mainGame.highScores[2])}</div>
        </div>
    </div>;
};
function SecureMenu() {
    const s7a = {
        textAlign: "center",
        background: "black radial-gradient(circle at center, var(--FLOORCOLOR) 0px, transparent 512px)",
        height: window.innerHeight - 200,
        padding: 100
    }
    const s7b = {
        backgroundColor: "rgba(0,0,0,0.8)",
        height: "100%",
        overflow: "auto"
    };
    const c3a = function () {
        mainGame.spawnRate = difArr[(difArr.indexOf(mainGame.spawnRate) + 1) % difArr.length];
    }
    const c3b = function () {
        mainGame.preferredMusic = (mainGame.preferredMusic + 1) % numberOfMusicThemes;
        mainGame.musicTheme = mainGame.preferredMusic;
    }
    const c3c = function () {
        mainGame.numOfPeople = 2 + ((mainGame.numOfPeople - 1) % 9);
    }
    const c3z = function () {
        mainGame.start(1);
    }
    const c3w = function () {
        mainGame.reset();
    }
    return <div style={s7a}>
        <div style={s7b}>
            <div className="menu-option" onClick={c3w}>Back</div>
            <div className="menu-option" onClick={c3a}>Difficulty: {diffs[mainGame.spawnRate]}</div>
            <div className="menu-option" onClick={c3b}>Music: {["TRAP", "ROCK"][mainGame.preferredMusic]}</div>
            <div className="menu-option" onClick={c3c}>People (of each type): {mainGame.numOfPeople}</div>
            <div className="menu-option" onClick={c3z}>Start</div>
        </div>
    </div>
};
function PooperMenu() {
    const s7a = {
        textAlign: "center",
        background: "black radial-gradient(circle at center, var(--FLOORCOLOR) 0px, transparent 512px)",
        height: window.innerHeight - 200,
        padding: 100
    }
    const s7b = {
        backgroundColor: "rgba(0,0,0,0.8)",
        height: "100%",
        overflow: "auto"
    };
    const c3b = function () {
        mainGame.preferredMusic = (mainGame.preferredMusic + 1) % numberOfMusicThemes;
        mainGame.musicTheme = mainGame.preferredMusic;
    }
    const c3c = function () {
        mainGame.numOfPeople = 2 + ((mainGame.numOfPeople - 1) % 9);
    }
    const c3z = function () {
        mainGame.start(2);
    }
    const c3w = function () {
        mainGame.reset();
    }
    return <div style={s7a}>
        <div style={s7b}>
            <div className="menu-option" onClick={c3w}>Back</div>
            <div className="menu-option" onClick={c3b}>Music: {["TRAP", "ROCK"][mainGame.preferredMusic]}</div>
            <div className="menu-option" onClick={c3c}>People (of each type): {mainGame.numOfPeople}</div>
            <div className="menu-option" onClick={c3z}>Start</div>
        </div>
    </div>
};
const difArr = [1, 1.02, 1.05];
const diffs = {
    1: "EASY",
    1.02: "MEDIUM",
    1.05: "HARD"
};
function PoPoOverlay() {
    const fn5a = function () {
        return mainGame.popo === 1 ? "" : "Turn the music down! The po-po is coming!";
    }
    const styl3sA = {
        opacity: mainGame.popo,
        paddingTop: window.innerHeight / 2 - 64,
        height: window.innerHeight + 200,
        width: window.innerWidth + 200
    };
    return <div className="po-po-overlay" style={styl3sA}>{fn5a()}</div>;
}
const c3a = function () {
    mainGame.reset();
    pausepage = false;
};
const c3b = function () {
    togglePause();
};
let pauseMessage = "Paused";
function PauseMenu({ resume }) {
    const s1z = {
        position: "absolute",
        width: window.innerWidth,
        height: window.innerHeight,
        top: window.innerHeight / 3
    };
    const s1a = {
        textAlign: "center",
        background: "black radial-gradient(circle at center, var(--FLOORCOLOR) 0px, transparent 256px)",
        padding: 40,
        width: window.innerWidth / 4,
        marginLeft: window.innerWidth * 3 / 8 - 40,
        color: "#9fc7e3"
    };
    const s1b = {
        backgroundColor: "rgba(0,0,0,0.8)",
        height: "100%",
        overflow: "auto",
        padding: 10
    };
    return <div style={s1z} className="pause-menu">
        <div style={s1a}>
            {pauseMessage}
            <br /><br />
            <div style={s1b}>
                {resume ? <><div className="pause-option menu-option" onClick={c3b}>Resume</div><br /></> : <></>}
                <div className="pause-option menu-option" onClick={c3a}>Return To Main Menu</div>
            </div>
        </div>
    </div>;
};
PauseMenu.defaultProps = {
    resume: true
};
const keys = {};
let pausepage = false;
function togglePause() {
    pausepage = !pausepage;
}
function handleMouseDown({ button, cantSkip }) {
    if (mainGame.isPaused) return;
    if (!button) {
        if ((tutorialStage === 1 || tutorialStage === 5 || tutorialStage === 11 || tutorialStage === 13 || tutorialStage === 21 || tutorialStage === 23) && !cantSkip) tutorialStage++;
        if (mainGame.player.pot) {
            mainGame.player.pot.collides = true;
            let v5a = Math.circ(66, mainGame.player.pot.a4a, ...mainGame.player.sca());
            mainGame.player.pot.x = v5a[0];
            mainGame.player.pot.y = v5a[1];
            mainGame.player.hasGun = !mainGame.guard;
            delete mainGame.player.pot.attach;
            delete mainGame.player.pot;
        }
        else if (mainGame.player.pizza) {
            let v5a = Math.circ(18 + mainGame.player.radius, mainGame.player.pizza.a4a, ...mainGame.player.sca());
            mainGame.player.pizza.x = v5a[0];
            mainGame.player.pizza.y = v5a[1];
            mainGame.player.hasGun = !mainGame.guard;
            delete mainGame.player.pizza.attach;
            delete mainGame.player.pizza;
        }
        else if (mainGame.player.hasGun) {
            mainGame.player.fire();
        }
    }
    if (button === 2) mainGame.player.interact();
}
document.addEventListener('mousedown', handleMouseDown);
document.addEventListener('keydown', ({ key }) => {
    if (mainGame.player.health.dead || mainGame.hasMainMenu) return;
    if (key === "Esc" || key === "Escape") togglePause();
    keys[key.toLowerCase()] = true;
});
document.addEventListener('keyup', ({ key }) => {
    delete keys[key.toLowerCase()];
});
document.addEventListener("mousemove", function ({ pageX, pageY }) {
    if (mainGame.isPaused) return;
    mainGame.mouse.x = pageX;
    mainGame.mouse.y = pageY;
});
function GameScreenBlock({ block, color }) {
    const position = { ...block };
    const styles = {
        backgroundColor: color,
        display: "inline-block",
        ...Math.w2s(...position.sca()).acj().css(),
        position: "absolute",
        width: block.w,
        height: block.h
    };
    if (styles.top >= window.innerHeight ||
        styles.top <= -block.h ||
        styles.left >= window.innerWidth ||
        styles.left <= -block.w) return <></>;
    return <div style={styles}></div>
};
GameScreenBlock.defaultProps = {
    color: "#000"
};
function GameScreenBody({ entity, color }) {
    const position = { ...entity };
    const styles = {
        padding: entity.radius,
        backgroundColor: color,
        display: "inline-block",
        borderRadius: entity.radius,
        ...Math.w2s(...position.sca(), entity.radius).acj().css(),
        position: "absolute",
        ...entity.styles
    };
    const s4 = {
        position: "absolute",
        left: styles.left - 12,
        top: styles.top + 80
    };
    if (styles.top >= window.innerHeight ||
        styles.top <= -entity.radius * 2 ||
        styles.left >= window.innerWidth ||
        styles.left <= -entity.radius * 2) {
        entity.isOffScreen = true;
        return <></>;
    }
    entity.isOffScreen = false;
    if (entity.src) {
        delete styles.padding;
        delete styles.backgroundColor;
        delete styles.display;
        delete styles.borderRadius;
        styles.width = entity.radius * 2;
        styles.height = entity.radius * 2;
        styles.textAlign = "center";
        styles.color = "#fff";
        if (!entity.isInLineOfSight()) return <div style={styles}><img alt="" src={entity.src} width={entity.keepDimensions ? "" : entity.radius * 2} height={entity.keepDimensions ? "" : entity.radius * 2} /><br />{entity.dist(mainGame.player) < 128 && entity.collides ? `${entity.text}` : ""}</div >;
        else return <>
            <img style={styles} alt="" src={entity.src} width={entity.radius * 2} height={entity.radius * 2} />
            <HealthBar color="#d04" mw={96} h={12} health={entity.health} s3={s4} />
        </>;
    }
    const fn2a = function () {
        const s9a = {
            position: "absolute",
            top: styles.top - 24,
            left: styles.left + entity.radius - 24,
            transform: `scale(1.5,.375) rotateZ(${Date.now() / 2}deg)`
        };
        return entity.isStunned ? <img alt="" src={starsFGame.default} style={s9a} /> : <></>;
    };
    if (!entity.isInLineOfSight()) return <div style={styles}></div>;
    else return <>
        <div style={styles}></div>
        {fn2a()}
        <HealthBar color="#d04" mw={96} h={12} health={entity.health} s3={s4} />
    </>;
};
GameScreenBody.defaultProps = {
    color: "#000"
};
function _gma() {
    let g0 = mainGame.guard;
    let a0 = g0 ? g0 : mainGame.player;
    let b0 = g0 ? g0.tg : mainGame.player.tg;
    const off = [b0[0] - a0.x, b0[1] - a0.y];
    return Math.ang(0, 0, ...off);
}
let lightLocation = [window.innerWidth / 2, window.innerHeight / 2];
function GameAim({ a, b }) {
    let a0 = a ? a : mainGame.player;
    let b0 = b ? b : mainGame.mouse;
    const off = [b0.x - a0.x, b0.y - a0.y];
    const a_s = {
        backgroundColor: "#fff",
        width: 8,
        height: Math.hypot(...off),
        position: "absolute",
        ...Math.w2s(...a0.sca()).acj().css(),
        transformOrigin: "top center",
        transform: `rotateZ(${Math.ang(0, 0, ...off) + 180}deg)`
    }
    return <div style={a_s}></div>;
}
Math.ang = function (cx, cy, ex, ey) {
    return Math.atan2(ey - cy, ex - cx) * 180 / Math.PI + 90;
}
Math.circ = function (r, t, x, y) {
    return [r * Math.sin(t * this.PI / 180) + x, y - r * Math.cos(t * this.PI / 180)];
}
function HealthBar({ health, mw, h, s3 }) {
    let { percent, _h, max, color } = health;
    const s0 = {
        height: h
    }
    const s1 = {
        ...s0,
        width: mw,
        border: "4px solid white",
        margin: 8,
        ...s3
    }
    const s2 = {
        ...s0,
        width: percent * mw,
        backgroundColor: color,
        color: "white",
        textAlign: "center",
        fontSize: h * 5 / 6
    }
    const fn4a = function () {
        return max > 1 ? `${Math.ceil(_h)}/${max}` : "";
    }
    return <div style={s1}><div style={s2}>{fn4a()}</div></div>;
}
HealthBar.defaultProps = {
    h: 36,
    s3: {}
}
function LightCover({ angle }) {
    const ls1 = {
        position: "absolute",
        transform: `rotateZ(${angle}deg)`,
        top: lightLocation[1] - 576,
        left: lightLocation[0] - 576,
        width: 1152,
        height: 1152,
        display: "inline-block"
    };
    const ls2 = {
        width: 1152,
        height: 576,
        position: "absolute",
        backgroundColor: "#000"
    };
    const ls3 = {
        width: 576,
        height: 1152,
        position: "absolute",
        backgroundColor: "#000"
    };
    return <div style={ls1}>
        <div style={ls2}></div>
        <div style={ls3}></div>
    </div>;
}
let msg = "";
let lastMsg = 0;
function addMessage(t) {
    //if (t === msg) return;
    msg = t;
    lastMsg = mainGame.now;
}
function Messages() {
    const c2a = mainGame.now - lastMsg;
    const s3a = {
        opacity: Math.max(0, Math.min(c2a / 1000, 1, 1 - (c2a - msg.length * 100 - 1000) / 1000)),
    };
    /*
    const _s3b = {
        transform: `scale(1,${s3a.opacity ** 2})`,
        transformOrigin: "top center"
    }*/
    return <div className="message-cont" style={s3a}><pre>{s3a.opacity > 0.01 ? msg : ""}</pre></div>;
}
let isInside = true;
let trgBrightness = 0.5;
let _ll = 0.5;
function getLightLevels() {
    isInside = Math.abs(mainGame.player.x) < 500 && Math.abs(mainGame.player.y) < 500;
    trgBrightness = isInside ? 0.5 : 0.975;
    return 0.975 * mainGame.popo + _ll * (1 - mainGame.popo);
}
function HUD() {
    const hStyle = {
        color: "#fff",
        margin: 8,
        position: "absolute",
        padding: 8,
        backgroundColor: "rgba(0,0,0,0.5)"
    };
    return <div style={hStyle}>HEALTH:
        <HealthBar mw={300} health={mainGame.player.health} />
        FUN LEVEL:
        <HealthBar mw={300} health={mainGame.funLevel} />
        SCORE: {Math.rdv(mainGame.score)}<br />
        HIGH SCORE: {Math.rdv(mainGame.highScores[mainGame.mode])}
        <Messages />
    </div>;
}
function ShadowOverlay(p) {
    const s7a = {
        ...p,
        display: "inline-block",
        width: window.innerWidth,
        height: window.innerHeight,
        position: "absolute",
        top: -100,
        left: -100,
        border: "100px solid black"
    }
    return <div style={s7a} className="darkness-overlay">
        <LightCover angle={Math.round(_gma() - 135)} />
    </div>;
}
ShadowOverlay.defaultProps = {
    opacity: 0.99
};
const Menu = {
    Current: MainMenu,
    change(New = MainMenu) {
        this.Current = New;
    }
};
const tips = [
    "Party pooper bots cannot throw pots at the security guard player.",
    "The security guard player can take pizza from the partygoers, but the bot cannot.",
    "In security guard mode, tazing party poopers will cause them to give you ten points when they despawn.",
    "The party pooper bots can come in very large numbers when playing as a guard.",
    "The security guart bot can only interact with the DJ board and the subwoofer.",
    "As the security guard, simply guarding the inner entrance to the party floor is a good strategy.",
    "In party pooper mode, dissatisfied pizza lovers will increase the rate by which you score increments.",
    "The tutorial game mode has a recorded and saved high score, it just isn't displayed on the front page.",
    "The po-po will come and it's game over if anybody dies or the music stays too loud for too long."
];
let tipIndex = 0;
function FullMenu() {
    const STY135 = {
        transition: ".5s ease",
        position: "absolute",
        top: window.innerHeight - 150,
        left: tipIndex * -window.innerWidth,
        color: "#fff"
    }
    const selyts = {
        width: window.innerWidth,
        position: "absolute",
        top: 0,
        display: "inline-block",
        textAlign: "center"
    };
    const span1 = {
        position: "absolute",
        bottom: 16,
        left: 16,
        color: "#fff"
    };
    const span2 = {
        position: "absolute",
        bottom: 16,
        right: 16,
        color: "#fff"
    };
    const div1 = {
        position: "absolute",
        top: 32,
        left: 0,
        color: "#fff",
        textAlign: "center",
        letterSpacing: "1em",
        width: window.innerWidth,
        fontSize: "2em"
    };
    return <>
        <Menu.Current />
        <div style={STY135}>
            {tips.map(function (tip, index) {
                const ms1 = {
                    left: index * selyts.width
                }
                Object.assign(ms1, selyts);
                return <div key={index} style={ms1}>{tip}</div>;
            })}
        </div>
        <span style={span1}>by Ace V.</span>
        <span style={span2}>v1.0.0</span>
        <div style={div1}>PARTY PATROL</div>
    </>;
}
function GameFrame() {
    const m = mainGame._ae2;
    const b = mainGame.blocks;
    let gds = mainGame.entities.filter(g => g.hasGun);
    let g = mainGame.guard ? mainGame.guard : mainGame.player;
    if (mainGame.hasMainMenu) return <FullMenu />;
    const fn1a = function () {
        return gds.length || mainGame.player.hasGun ? <GameAim a={g} b={g.tg.acj()} /> : <></>;
    }
    setLightLocation(...Math.w2s(...g.sca()));
    const fn1b = function () {
        return mainGame.player.health.dead && Date.now() - mainGame.player.health.lD > 667 ? <PauseMenu resume={false} /> : pausepage ? <PauseMenu /> : <></>;
    }
    const fn1c = function () {
        return mainGame.popo ? <PoPoOverlay /> : <></>;
    }
    const dStyle = {
        transition: ".5s ease"
    };
    if (mainGame.isPaused) dStyle.filter = "blur(15px)";
    return <>
        <div style={dStyle}>
            <FloorFrame />
            {b.map(function (b, n) {
                return <GameScreenBlock block={b} key={n} />
            })}
            {fn1a()}
            {m.map(function (e, n) {
                return <GameScreenBody entity={e} key={n} color={e.color} />
            })}
            {gds.map(function (e, n) {
                return <GameScreenBody entity={e} key={n} color={e.color} />
            })}
            <GameScreenBody entity={mainGame.player} color={mainGame.player.color} />
            <ShadowOverlay opacity={getLightLevels()} />
            {allPoofs.map(function (p, n) {
                return <Poof poof={p} key={n} />
            })}
            {fn1c()}
            <HUD />
        </div>
        {fn1b()}
    </>;
}
export default function GameRoot() {
    const [frames, setFrames] = useState(1);
    useEffect(function () {
        mainGame.lrt = Date.now();
        setInterval(function () {
            setFrames(f => f + 1);
            mainGame.update();
        }, 10);
        window.Game = mainGame;
        window.Util = gameUtil;
        document.body.oncontextmenu = function () {
            return false;
        }
    }, []);
    const pStyle = {
        display: "none"
    };
    const fn3a = function () {
        return mainGame.hasMainMenu ? <></> : <AllSounds />;
    }
    return <div>
        <p style={pStyle}>{frames}</p>
        <GameFrame />
        {fn3a()}
    </div>;
};